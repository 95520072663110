import React from "react"
import PageLayout from "../components/PageLayout"
import ContentLayout from "../components/ContentLayout"
import SEO from "../components/seo"
import { css } from "@emotion/core"

export default () => (
  <PageLayout>
    <SEO title="About zenshare, the easiest way to keep your team in the loop about your company news" />
    <ContentLayout>
      <h1
        css={css`
          font-weight: normal;
        `}
      >
        About Zenshare
      </h1>
    </ContentLayout>
  </PageLayout>
)
